import React from 'react';
import { Link } from 'gatsby';

import CardMetaHeader from './header';
import CardMetaBody from './body';

import {getNavigateLink} from '../../utilities/utilfns';

import "./styles.css";


function CardMetaData(props){

    const { cardDetails , showFooter, showBestFor , customStyle } = props;
    return (
        <div className='cards-metadata-container' key={cardDetails.key}>
            <CardMetaHeader headerDetails={cardDetails}/>
            <CardMetaBody key={cardDetails.key} cardDetails={cardDetails} showBestFor={showBestFor} />
            {props.children}
            { showFooter && (  <div className='cards-metadata-footer'> 
                <div className='navigate-text'>
                        <Link 
                                to={getNavigateLink(`/card-details/${cardDetails.key}`)}
                                key={cardDetails.cardId} 
                            >                     
                        <div style={{fontWeight:"300"}}> {'For more information click here'} </div>
                            </Link>
                    </div>
                    <Link 
                        key={cardDetails.cardId} 
                        to={getNavigateLink(`/card-details/${cardDetails.key}`)}
                        className='more-details'
                    >      
                        <div style={{marginRight:"1rem"}}> {"Get Started"}  </div>               
                        <div> 
                            <img 
                                alt="arrow"
                                src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow.svg"} /> 
                            </div> 
                    </Link>

            </div>)}

        </div> 
    )

}


export default CardMetaData;