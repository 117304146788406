export const displayBoxes = [
    {
        key : "rewardType",
        title : "Reward Type",
        descriptionKeys : ["cardType"],
    },
    {
        key : "welcome-benefits",
        title : "Welcome Benefits",
        descriptionKeys : ["joiningAnnualAndMilestoneBenefits.joiningBenefit.shortDescription"]
    },
    {
        key : "minimum-income",
        title : "Minimum Income",
        descriptionKeys : ["eligibility.minimumITR"]
    },
    {
        key : "domestic-lounge-access",
        title : "Lounge Access",
        descriptionKeys : ["features.loungeAccess.forCardHolders.domestic",]
    },
    // {
    //     key : "international-lounge-access",
    //     title : "International lounge",
    //     descriptionKeys : [ "features.loungeAccess.forCardHolders.international"]
    // },
    // {
    //     key : "railway-lounge-access",
    //     title : "Railway lounge",
    //     descriptionKeys : [ "features.loungeAccess.forCardHolders.railway"]
    // },
]
