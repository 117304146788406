import React, { useState } from 'react';
import { getProperty } from 'dot-prop';

import HighlightBox from '../highlightBox/index';
import BestForIcons from '../bestForIcons';

import {displayBoxes} from './constants';
import {getDescription} from "../../utilities/utilfns";

import "./styles/body.css";


function CardMetaBody(props){

    const {cardDetails = {} , showBestFor = true} = props;
    const {imageUrl , key, savings , bestFor, displayName  } = cardDetails;
    const [ isChecked, setChecked ] = useState(false)

    function addToCompare(ev){
        const cardId = ev.target.id;
        let compareCardIds = JSON.parse(localStorage.getItem("compareCardIds") || "[]");
        if(compareCardIds.findIndex( ele => ele.id === cardId ) != -1){
            setChecked(false);
            compareCardIds = compareCardIds.filter( ele => ele.id != cardId );
            localStorage.setItem("compareCardIds",JSON.stringify(compareCardIds));
            window.dispatchEvent(new Event("storage"));
        }else{
            if(compareCardIds.length < 3){
                compareCardIds.push({ id : cardId, imageUrl});
                localStorage.setItem("compareCardIds",JSON.stringify(compareCardIds));
                window.dispatchEvent(new Event("storage"));
                setChecked(true);
            }else{
                setChecked(false);
            }
        }
    }

    return (            
        <div className='cards-metadata-body'> 

            <div className='cards-metadata-body-sec-1'> 
                <div style={{display : "flex", justifyContent:"space-between", alignItems : "center"}} >
                    <img className='cards-metadata-body-sec-1-img' alt={displayName} src={imageUrl} />  
                    <div className='fees-and-values-card-meta'>
                        <div style={{color : "#3D8BFF", margin : "0.5rem 0"}}> {"Joining Fees"}</div>
                        <div className='card-meta-fees-value'> {cardDetails.feesAndCharges.joiningFees}</div>
                        <div style={{color : "#3D8BFF", margin : "0.5rem 0"}}> {"Annual Fees"}</div>
                        <div className='card-meta-fees-value'> {cardDetails.feesAndCharges.annualFees}</div>
                    </div>
                </div>

                <div 
                    style={{marginTop:"1rem", alignItems:"center", cursor: "pointer", display : "flex"}} 
                    id={key} 
                    key={key}
                    onClick={addToCompare}>
                    <input 
                        type="checkbox" 
                        className='add-to-compare-checkbox'
                        checked={isChecked} 
                        value={key} id={key} style={{cursor : "pointer", borderColor : "#3D8BFF"}} /> 
                     <span id={key} style={{color:"#3D8BFF"}}> {'Add to Compare'} </span>
                </div>

                { showBestFor && bestFor.length > 0 && <div className='best-for-container'>
                    <span> {"Best for these"} </span>
                    <div className="best-for-icons-container">  {<BestForIcons bestFor={bestFor} />} </div>
                </div>}

            </div>
            <div className='cards-metadata-body-sec-2'> 

                <div className='percentage-savings-container'>
                    <div className='percentage-savings-heading'> {"Percentage Savings range"} </div>
                    <div className='percentage-savings-body'> 
                        <HighlightBox 
                            customStyle={{
                                container : { maxHeight : "5rem" , maxWidth: "10%", height : "auto" , display:"flex", flexDirection : "column", alignItems : "center"} , 
                                title : { fontWeight:"400" , fontSize : "1rem", color: "#000"}
                            }}
                            description={savings.percentage}
                            showTitleTooltip={true}
                            icon={"https://i.postimg.cc/5yGN9DV5/rupee-gold-coin-10889.png"}
                            />
                        <div className='percentage-savings-text'> {savings.details} </div>
                    </div>
                </div>

                <div className='highlights-container'>
                    {displayBoxes.map(ele => <HighlightBox 
                        container
                        key={ele.key} 
                        id={ele.key}
                        title={ele.title}
                        hasMultiLinedDescription={ele.descriptionKeys.length > 1} 
                        description={ getDescription(ele, cardDetails) }  /> 
                    ) }
                </div>
            </div>
        </div>
    )
}


export default CardMetaBody;