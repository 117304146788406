import { navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import "./styles.css"

function CategorySidebar(props){

    const {cardCategories , selectedCategory} = props;
    const selRef = useRef();
    const sideRef = useRef();

    function clickCategory(ev){
        ev.preventDefault();
        if(ev.target.id){
            navigate("/card-category/" + ev.target.id + window.location.search);
        }
    }

    function scrollLeft(ev){
        ev.preventDefault();
        ev.stopPropagation();     
        document.getElementsByClassName("category-sidebar")[0].scrollLeft -= 200;
    }

    function scrollRight(ev){
        ev.preventDefault();
        ev.stopPropagation();
        document.getElementsByClassName("category-sidebar")[0].scrollLeft +=  200;        
    }

    useEffect( () => {
        // sideRef.current.scroll(selRef.current.offsetLeft);
        selRef.current.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
    } , [])


    return (
        <div
            ref={sideRef} 
            className='category-sidebar-container' 
            onClick={clickCategory}>
            <div className='category-sidebar' >
                {cardCategories.map( category => (
                    <div 
                        className={selectedCategory.key == category.key ? "category-sidebar-option-selected" : "category-sidebar-option" } 
                        ref={ selectedCategory.key == category.key ? selRef : null}
                        id={category.key} 
                        key={category.key}
                    > 
                        <div className='category-opt-cont' id={category.key}>
                            <img 
                                alt={category.key}
                                className='category-sidebar-img-div' 
                                src={category.icon} 
                                id={category.key}/>
                            <div className='category-sidebar-option-heading' id={category.key}> {category.displayName} </div>
                        </div>
                        {/* backgroundColor:"#E6E6E6", width:"100%", height:"1.5px" */}
                        {/* { selectedCategory.key == category.key &&  <div className='category-sidebar-option-subheading' id={category.key}> {category.description}  </div>} */}
                    </div>                
                ) )}
            </div>
            <img 
                alt="icon-left"
                className="icon-sidebar icon-left"  
                src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/left-arrow.svg" 
                onClick={scrollLeft}/>  
            <img 
                alt="icon-right" 
                className="icon-sidebar icon-right" 
                src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/right-arrow.svg" onClick={scrollRight}/>

        </div>
    )

}

export default CategorySidebar;