import React, { Fragment } from 'react';
import { graphql } from 'gatsby'

import { categoriesOfCard } from '../../utilities/constants';
import CategorySidebar from '../../components/categoriesPage/categorySidebar';
import CardMetaData from '../../components/cardMetaData';
import Layout from '../../components/layout/index';

import "./categorypage.styles.css";
import "../../components/categoriesPage/styles.css";

import { getCurrentMonth } from '../../utilities/utilfns';

function CardCategories({data}){
    const { allMongodbMmcnewCarddatas , mongodbMmcnewCategorydatas} = data;
    const cardsArray = mongodbMmcnewCategorydatas.cardsIdList.map( ele =>  allMongodbMmcnewCarddatas.nodes.filter( item => item.key === ele )[0]);
    const selectedCategory = categoriesOfCard.filter( ele => ele.key === mongodbMmcnewCategorydatas.key)[0] || {};
    return (
    <Layout> 
        <section className="category-header"> 
            <div className="category-header-title" style={{ marginBottom:"0.2rem"}}> {selectedCategory.heading} </div>
            <div className="category-header-title" style={{ color: "#168AEB"}}> {selectedCategory.subheading}</div>
        </section>
        <section className='category-body'> 
            <CategorySidebar 
                key={selectedCategory.key}
                cardCategories={categoriesOfCard}
                selectedCategory={selectedCategory}> 
            </CategorySidebar>
            <div className='selected-category-description'> {selectedCategory.description} </div> 
            <div className='category-cards-container'> 
                {cardsArray.map( ele => <CardMetaData 
                    key={ele.key} 
                    cardDetails={ele} 
                    showFooter={true} 
                    customStyle={{maxWidth : "90%"}}/> 
                )}
            </div>
        </section>
    </Layout>
    
    
    )

}

export const Head = ({data}) => {
    const { mongodbMmcnewCategorydatas} = data;
    const selectedCategory = categoriesOfCard.filter( ele => ele.key === mongodbMmcnewCategorydatas.key)[0] || {};
    const content = selectedCategory.description;

    return (
    <Fragment>
        <title> Best 5+ {selectedCategory.displayName + " Credit Cards in India -" + getCurrentMonth() + " | Easy Apply " } </title>
        <meta name="description"  content={content} />
    </Fragment>)
}


export default CardCategories;

export const query = graphql`
query ($key: String) {
    mongodbMmcnewCategorydatas(key :{eq :$key}) {
      id,
      key,
      displayName,
      cardsIdList
    }
    allMongodbMmcnewCarddatas(filter : { bestFor : { eq : $key } }){
        nodes {
            bankId
            key
            cardType
            cardCategory
            displayName
            imageUrl
            applyLinkUrl
            rating
            bestFor
            savings {
                details
                percentage
            }
            joiningAnnualAndMilestoneBenefits {
                joiningBenefit { 
                    shortDescription
                    longDescription                
                }
            }
            feesAndCharges {
                annualFees
                joiningFees
            }
            rewards {
                accerlatedRewards
                rewardsList {
                    percentage
                    text
                }
            }
            eligibility {
                minimumITR
            }
            features {
                loungeAccess {
                forCardHolders {
                    domestic
                    international
                    railway
                    }
                }
            }
        }
    }
}`